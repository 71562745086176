import React from "react";
import {
	TextField,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Box,
} from "@mui/material";

// Define the props interface
interface LeftColumnProps {
	temperature: number;
	setTemperature: (value: number) => void;
	maxTokens: number;
	setMaxTokens: (value: number) => void;
	anthropicModel: string;
	setAnthropicModel: (value: string) => void;
	openAIModel: string;
	setOpenAIModel: (value: string) => void;
	googleModel: string;
	setGoogleModel: (value: string) => void;
}

const LeftColumn: React.FC<LeftColumnProps> = ({
	temperature,
	setTemperature,
	maxTokens,
	setMaxTokens,
	anthropicModel,
	setAnthropicModel,
	openAIModel,
	setOpenAIModel,
	googleModel,
	setGoogleModel,
}) => {
	return (
		<Box
			sx={{
				padding: 2, // Add padding for internal space
				display: "flex",
				flexDirection: "column",
				gap: 2, // Add gap between the elements
				borderRadius: 3, // Round the edges
				boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Add subtle shadow for a modern look
				backgroundColor: "white", // Set background color
			}}
		>
			{/* Temperature Input */}
			<TextField
				fullWidth
				label="Temperature"
				type="number"
				variant="outlined"
				value={temperature}
				inputProps={{ step: 0.1, min: 0, max: 1 }}
				onChange={(e) => setTemperature(parseFloat(e.target.value))}
				sx={{
					borderRadius: "12px",
				}}
			/>

			{/* Max Tokens Input */}
			<TextField
				fullWidth
				label="Max Tokens"
				type="number"
				variant="outlined"
				value={maxTokens}
				onChange={(e) => setMaxTokens(parseInt(e.target.value, 10))}
				sx={{
					borderRadius: "12px",
				}}
			/>

			{/* Anthropic Model Dropdown */}
			<FormControl fullWidth>
				<InputLabel>Anthropic Model</InputLabel>
				<Select
					value={anthropicModel}
					onChange={(e) => setAnthropicModel(e.target.value)}
					label="Anthropic Model"
					sx={{ borderRadius: "12px" }}
				>
					<MenuItem value="claude-3-5-sonnet-20240620">
						Claude 3.5 Sonnet
					</MenuItem>
					<MenuItem value="claude-3-opus-20240229">Claude 3 Opus</MenuItem>
					<MenuItem value="claude-3-haiku-20240307">Claude 3 Haiku</MenuItem>
				</Select>
			</FormControl>

			{/* OpenAI Model Dropdown */}
			<FormControl fullWidth>
				<InputLabel>OpenAI Model</InputLabel>
				<Select
					value={openAIModel}
					onChange={(e) => setOpenAIModel(e.target.value)}
					label="OpenAI Model"
					sx={{ borderRadius: "12px" }}
				>
					<MenuItem value="gpt-4o">GPT-4o</MenuItem>
					<MenuItem value="gpt-4o-mini">GPT-4o mini</MenuItem>
				</Select>
			</FormControl>

			{/* Google Model Dropdown */}
			<FormControl fullWidth>
				<InputLabel>Google Model</InputLabel>
				<Select
					value={googleModel}
					onChange={(e) => setGoogleModel(e.target.value)}
					label="Google Model"
					sx={{ borderRadius: "12px" }}
				>
					<MenuItem value="gemini-1.5-pro">Gemini 1.5 Pro</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
};

export default LeftColumn;
