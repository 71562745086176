import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import routing
import LeftColumn from "./components/LeftColumn";
import RightColumn from "./components/RightColumn";
import SystemUserMessages from "./components/SystemUserMessages";
import ReactMarkdown from "react-markdown";
import NornooPage from "./NornooPage";
import AttritionPage from "./AttritionPage";

// Custom function to render structured text (Markdown support)
const renderResponse = (response: string | undefined) => {
	if (!response) return null;
	return <ReactMarkdown>{response}</ReactMarkdown>;
};

const App: React.FC = () => {
	// State variables for LeftColumn and RightColumn components
	const [temperature, setTemperature] = useState<number>(0);
	const [maxTokens, setMaxTokens] = useState<number>(256);
	const [anthropicModel, setAnthropicModel] = useState<string>(
		"claude-3-5-sonnet-20240620"
	);
	const [openAIModel, setOpenAIModel] = useState<string>("gpt-4o");
	const [googleModel, setGoogleModel] = useState<string>("gemini-1.5-pro");

	// State variables for responses and loading states
	const [responseAnthropic, setResponseAnthropic] = useState<string>("");
	const [responseOpenAI, setResponseOpenAI] = useState<string>("");
	const [responseGoogle, setResponseGoogle] = useState<string>("");
	const [loadingAnthropic, setLoadingAnthropic] = useState<boolean>(false);
	const [loadingOpenAI, setLoadingOpenAI] = useState<boolean>(false);
	const [loadingGoogle, setLoadingGoogle] = useState<boolean>(false);

	// System and user message state variables
	const [systemMessage, setSystemMessage] = useState<string>(
		"You are an assistant. Please help answer my questions"
	);
	const [userMessage, setUserMessage] = useState<string>("");

	// Disable the user and system input fields while waiting for responses
	const isDisabled = loadingAnthropic || loadingOpenAI || loadingGoogle;

	// Function to handle form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		// Clear previous responses and show the spinners
		setResponseAnthropic("");
		setResponseOpenAI("");
		setResponseGoogle("");
		setLoadingAnthropic(true);
		setLoadingOpenAI(true);
		setLoadingGoogle(true);

		const payload = {
			system_message: systemMessage,
			user_message: userMessage,
			temperature: temperature,
			max_tokens: maxTokens,
		};

		// Replace this with your actual API Gateway endpoint
		const apiGatewayUrl =
			"https://lsi3qi0psi.execute-api.us-east-1.amazonaws.com/prod/";

		try {
			// Send requests for all LLMs simultaneously using Promise.all
			const [anthropicResponse, openAIResponse, googleResponse] =
				await Promise.all([
					fetch(apiGatewayUrl, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							...payload,
							llm: "anthropic",
							model: anthropicModel,
						}),
					}),
					fetch(apiGatewayUrl, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							...payload,
							llm: "openai",
							model: openAIModel,
						}),
					}),
					fetch(apiGatewayUrl, {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							...payload,
							llm: "google",
							model: googleModel,
						}),
					}),
				]);

			// Convert the responses to JSON
			const [anthropicData, openAIData, googleData] = await Promise.all([
				anthropicResponse.json(),
				openAIResponse.json(),
				googleResponse.json(),
			]);

			// Update the states with new responses
			setResponseAnthropic(anthropicData.anthropic_response);
			setResponseOpenAI(openAIData.openai_response);
			setResponseGoogle(googleData.gemini_response);
		} catch (error) {
			console.error("Error sending request:", error);
		} finally {
			setLoadingAnthropic(false);
			setLoadingOpenAI(false);
			setLoadingGoogle(false);
			setUserMessage(""); // Clear input field
		}
	};

	return (
		<Router>
			<Routes>
				{/* Route for the Nornoo Page */}
				<Route path="/nornoo" element={<NornooPage />} />
				<Route path="/attrition" element={<AttritionPage />} />

				{/* Main App Page Route */}
				<Route
					path="/"
					element={
						<Box
							sx={{ display: "flex", flexDirection: "row", minHeight: "100vh" }}
						>
							{/* Left Column */}
							<Grid
								item
								sx={{
									flexBasis: "15%",
									height: "100vh",
									borderRight: "2px solid #ccc",
									padding: 1,
									boxSizing: "border-box",
									fontSize: "12px",
								}}
							>
								<LeftColumn
									temperature={temperature}
									setTemperature={setTemperature}
									maxTokens={maxTokens}
									setMaxTokens={setMaxTokens}
									anthropicModel={anthropicModel}
									setAnthropicModel={setAnthropicModel}
									openAIModel={openAIModel}
									setOpenAIModel={setOpenAIModel}
									googleModel={googleModel}
									setGoogleModel={setGoogleModel}
								/>
							</Grid>

							{/* Right Column & SystemUserMessages */}
							<Grid
								item
								sx={{
									flexGrow: 1,
									height: "100vh",
									overflow: "hidden",
									display: "flex",
									flexDirection: "column",
								}}
							>
								<RightColumn
									loadingAnthropic={loadingAnthropic}
									responseAnthropic={renderResponse(responseAnthropic)}
									loadingOpenAI={loadingOpenAI}
									responseOpenAI={renderResponse(responseOpenAI)}
									loadingGoogle={loadingGoogle}
									responseGoogle={renderResponse(responseGoogle)}
								/>
								<Box sx={{ padding: 2, height: "120px", marginBottom: "10px" }}>
									<SystemUserMessages
										systemMessage={systemMessage}
										setSystemMessage={setSystemMessage}
										userMessage={userMessage}
										setUserMessage={setUserMessage}
										handleSubmit={handleSubmit}
										disabled={isDisabled} // Disable input fields when loading
									/>
								</Box>
							</Grid>
						</Box>
					}
				/>
			</Routes>
		</Router>
	);
};

export default App;
