import React, { useState } from "react";
import {
	Container,
	Box,
	Typography,
	Grid,
	TextField,
	Button,
	Paper,
	MenuItem,
} from "@mui/material";

const AttritionPage: React.FC = () => {
	const [inputs, setInputs] = useState({
		Name: "",
		GPA: "",
		Entrance_Score: "",
		Age: "",
		Gender: "",
		Financial_Aid: "",
		Study_Hours: "",
		Extracurriculars: "",
		Part_Time_Job: "",
		Living_On_Campus: "",
	});

	const [probabilities, setProbabilities] = useState<number[] | null>(null);

	// Handle form input changes
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setInputs({ ...inputs, [name]: value });
	};

	// Dummy handleSubmit for now, just to simulate probability output
	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		try {
			// Make a POST request to the API with the form data
			const response = await fetch(
				"https://lsi3qi0psi.execute-api.us-east-1.amazonaws.com/prod/attrition",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						Name: inputs.Name,
						GPA: parseFloat(inputs.GPA),
						Entrance_Score: parseFloat(inputs.Entrance_Score),
						Age: parseInt(inputs.Age),
						Gender: parseInt(inputs.Gender),
						Financial_Aid: parseInt(inputs.Financial_Aid),
						Study_Hours: parseFloat(inputs.Study_Hours),
						Extracurriculars: parseInt(inputs.Extracurriculars),
						Part_Time_Job: parseInt(inputs.Part_Time_Job),
						Living_On_Campus: parseInt(inputs.Living_On_Campus),
					}),
				}
			);

			const data = await response.json();
			if (response.ok) {
				// Ensure data.probabilities is an array before setting it to probabilities
				if (Array.isArray(data.probabilities)) {
					setProbabilities(data.probabilities);
				} else {
					console.error(
						"Error: probabilities is not an array",
						data.probabilities
					);
					setProbabilities(null); // Reset if probabilities isn't an array
				}
			} else {
				console.error("Error fetching probabilities:", data);
				setProbabilities(null);
			}
		} catch (error) {
			console.error("Error during prediction request:", error);
			setProbabilities(null);
		}
	};

	// Function to add data to DynamoDB (replace with actual API call)
	const handleAdd = async () => {
		try {
			const dataToSave = { ...inputs, probabilities };
			console.log("Data to be added to DynamoDB:", dataToSave);
			// Make an API call here to save dataToSave in DynamoDB
		} catch (error) {
			console.error("Error adding data:", error);
		}
	};

	// Function to clear the form and reset probabilities
	const handleClear = () => {
		setInputs({
			Name: "",
			GPA: "",
			Entrance_Score: "",
			Age: "",
			Gender: "",
			Financial_Aid: "",
			Study_Hours: "",
			Extracurriculars: "",
			Part_Time_Job: "",
			Living_On_Campus: "",
		});
		setProbabilities(null);
	};

	return (
		<Container maxWidth="sm">
			<Paper elevation={3} sx={{ padding: 4, borderRadius: 2, marginTop: 5 }}>
				<Typography variant="h4" component="h1" gutterBottom align="center">
					Individual Attrition
				</Typography>
				<Box component="form" onSubmit={handleSubmit} noValidate>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								label="Name"
								name="Name"
								value={inputs.Name}
								onChange={handleChange}
								variant="outlined"
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="GPA"
								name="GPA"
								value={inputs.GPA}
								onChange={handleChange}
								variant="outlined"
								type="number"
								required
								inputProps={{ step: "0.1", min: 0, max: 6 }} // GPA from 0-6
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Entrance Score"
								name="Entrance_Score"
								value={inputs.Entrance_Score}
								onChange={handleChange}
								variant="outlined"
								type="number"
								required
								inputProps={{ step: "0.1", min: 0, max: 100 }} // Score from 0-100
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Age"
								name="Age"
								value={inputs.Age}
								onChange={handleChange}
								variant="outlined"
								type="number"
								required
								inputProps={{ min: 0, step: "1" }} // Age as integer
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Gender"
								name="Gender"
								value={inputs.Gender}
								onChange={handleChange}
								select
								variant="outlined"
								required
							>
								<MenuItem value="1">Male</MenuItem>
								<MenuItem value="0">Female</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Financial Aid"
								name="Financial_Aid"
								value={inputs.Financial_Aid}
								onChange={handleChange}
								select
								variant="outlined"
								required
							>
								<MenuItem value="1">Yes</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Study Hours"
								name="Study_Hours"
								value={inputs.Study_Hours}
								onChange={handleChange}
								variant="outlined"
								type="number"
								required
								inputProps={{ step: "0.1" }} // Allow float values
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Extracurriculars"
								name="Extracurriculars"
								value={inputs.Extracurriculars}
								onChange={handleChange}
								select
								variant="outlined"
								required
							>
								<MenuItem value="1">Yes</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Part Time Job"
								name="Part_Time_Job"
								value={inputs.Part_Time_Job}
								onChange={handleChange}
								select
								variant="outlined"
								required
							>
								<MenuItem value="1">Yes</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Living On Campus"
								name="Living_On_Campus"
								value={inputs.Living_On_Campus}
								onChange={handleChange}
								select
								variant="outlined"
								required
							>
								<MenuItem value="1">Yes</MenuItem>
								<MenuItem value="0">No</MenuItem>
							</TextField>
						</Grid>
					</Grid>
					<Box textAlign="center" mt={3}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							size="large"
							sx={{ textTransform: "none", borderRadius: 2 }}
						>
							Get Prediction
						</Button>
					</Box>
				</Box>
				{probabilities && (
					<Box mt={4} p={2}>
						<Typography variant="h6" component="h2">
							Predicted Probabilities
						</Typography>
						<Box mt={1}>
							{probabilities.map((prob, index) => (
								<Typography key={index} variant="body1">
									Year {index === 0 ? "No Failure" : `Fail Year ${index}`}:
									<strong> {prob.toFixed(2)}%</strong>
								</Typography>
							))}
						</Box>
						<Box textAlign="center" mt={3}>
							<Button
								variant="outlined"
								color="secondary"
								size="medium"
								sx={{ marginRight: 2, textTransform: "none", borderRadius: 2 }}
								onClick={handleAdd}
							>
								Add
							</Button>
							<Button
								variant="outlined"
								color="error"
								size="medium"
								sx={{ textTransform: "none", borderRadius: 2 }}
								onClick={handleClear}
							>
								Clear
							</Button>
						</Box>
					</Box>
				)}
			</Paper>
		</Container>
	);
};

export default AttritionPage;
