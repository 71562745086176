import React, { useState, useEffect, useCallback } from "react";
import {
	Box,
	TextField,
	Button,
	Card,
	CardContent,
	Typography,
	Grid,
	Divider,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	IconButton,
	Checkbox,
	FormControlLabel,
	FormGroup,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Add, Remove, CheckCircleOutline } from "@mui/icons-material";
import axios from "axios"; // Make sure to import axios for API calls
import CircularProgress from "@mui/material/CircularProgress";

// Initial form sections data
const initialSections = [
	{
		id: "student_info",
		label: "Student Information",
		content: "Enter student details here",
	},
	{
		id: "formulation",
		label: "Formulation",
		content: "Add formulation details here",
	},
	{
		id: "ingredients",
		label: "Ingredients",
		content: "List the ingredients here",
	},
	{
		id: "manufacturer_info",
		label: "Manufacturer's Product Information",
		content: "Add manufacturer info here",
	},
	{
		id: "equipment",
		label: "Equipment and Instrumentation",
		content: "Describe equipment and tools",
	},
	{
		id: "preparation",
		label: "Method of Preparation",
		content: "Describe the preparation method",
	},
	{
		id: "calculations",
		label: "Calculations",
		content: "Enter the calculations",
	},
	{
		id: "label",
		label: "Label",
		content: "Add label details here",
	},
	{
		id: "description",
		label: "Description of Finished Product and Cleanliness",
		content: "Describe product and cleanliness",
	},
	{ id: "questions", label: "Questions", content: "Add any questions here" },
];

const NornooPage: React.FC = () => {
	const [sections, setSections] = useState(initialSections);
	const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

	// State for Student Information fields
	const [studentInfo, setStudentInfo] = useState({
		studentName: "Jeremy Hagler",
		labStation: "19",
		scenarioId: "1",
		scenarioName: "Ivermectin",
	});

	// State for the Formulation section fields
	const [formulation, setFormulation] = useState({
		name: "Ivermectin 3mg capsules",
		strength: "Ivermectin 3mg",
		quantityPrepared: "10 capsules",
		quantityDispensed: "6 capsules",
		therapeuticCategory: "Anthelmintic",
		routeOfAdministration: "Oral",
		dosageForm: "capsule",
	});

	// State for Ingredients table rows
	const [ingredientRows, setIngredientRows] = useState([
		{
			ingredient: "Ivermectin USP",
			quantity: "0.201g",
			description: "Fine white powder",
			use: "Active Pharmaceutical Ingredient",
		},
		{
			ingredient: "Microcrystalline Cellulose NF (PH-105) Powder #0",
			quantity: "14.8g",
			description: "Fine white powder",
			use: "Diluent",
		},
		{
			ingredient: "Microcrystalline Cellulose NF (PH-105) Powder #1",
			quantity: "13.82g",
			description: "Fine white powder",
			use: "Diluent",
		},
	]);

	// State for Manufacturer's Product Information rows
	const [manufacturerRows, setManufacturerRows] = useState([
		{
			ingredient: "Ivermectin USP (For Human Use) Powder",
			manufacturer: "PCCA",
			lot: "C200967",
			expirationDate: "8/23/2024",
		},
		{
			ingredient: "Microcrystalline Cellulose NF (PH-105) Powder",
			manufacturer: "PCCA",
			lot: "C202319",
			expirationDate: "7/30/2025",
		},
	]);

	// State for Equipment and Instrumentation list
	const [equipmentList, setEquipmentList] = useState([
		{ equipment: "Electronic balance" },
		{ equipment: "Spatula - plastic" },
		{ equipment: "Weigh boat" },
		{ equipment: "Capsule shell #1 or #0 capsule vial" },
		{ equipment: "Ointment slab" },
	]);

	// State for Description of Finished Product and Cleanliness fields
	const [descriptionFields, setDescriptionFields] = useState({
		station: "clean", // 'clean' or 'dirty'
		elegantCapsules: "yes", // 'yes' or 'no'
		colorUniformity: "yes", // 'yes' or 'no'
		averageWeight: "correct", // 'correct' or 'incorrect'
	});
	const getCurrentDate = () => {
		const today = new Date();
		const dd = String(today.getDate()).padStart(2, "0");
		const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
		const yyyy = today.getFullYear();
		return `${mm}/${dd}/${yyyy}`;
	};

	// State for Label section
	const [labelInfo, setLabelInfo] = useState({
		pharmacyName: "Gregory Family Pharmacy",
		pharmacyAddress: "1314 S. Dixie Hwy\nWest Palm Beach, FL 33401",
		dateDispensed: getCurrentDate(),
		serialNumber: "336795",
		patientName: "Pablo Aguiar",
		prescriberName: "Olivia O'Rourke, DO",
		drugName: "Ivermectin 3 mg oral capsules",
		directions: "Take one capsule by mouth once a month",
		expirationDate: "9/21/24",
		quantity: "6",
		refills: "1",
		dispenser: studentInfo.studentName,
	});
	// State for other sections (preparation, calculations, questions)
	const [otherSections, setOtherSections] = useState({
		preparation: `1. The stock amount of ivermectin (0.201g) and the amount of microcrystalline cellulose (13.2g) needed to make the stock powder mixture was weighed with a weigh boat separately on an electronic balance.
		2. The two powders were mixed thoroughly (with two plastic spatulas) using geometric dilution on an ointment slab. Briefly, an amount of microcrystalline cellulose equal to the amount of ivermectin was mixed initially with the help of a powder dye. Next, an amount of microcrystalline cellulose equal to the powder mix was mixed. This procedure was repeated until all the microcrystalline cellulose was incorporated.
		3. An aliquot (2g) was weighed out from the stock mixture that contained the amount of drug to make 10 capsules.
        4. Additional microcrystalline cellulose (0.62g - #1 OR 1.6g - #0) was weighed to bulk fill either #1 or #0 capsule shells to make 10 capsules.
        5. The additional microcrystalline cellulose was thoroughly mixed with the aliquot powder (with two plastic spatulas) using geometric dilution as described in step 2 above on an ointment slab.
        6. A powder mound was formed to hand punch 6 capsules.
        7. The capsules were weighed to verify a specified weight of 0.262g (#1) or 0.36g (#0).`,
		calculations: `Dilution factor (DF) = MWQ/strength of API per unit = 200mg/3mg = 66.67 ~ 67
		At new DF, quantity of API = 67 x 0.003g = 0.201g
        Stock powder = DF x MWQ = 67 x 0.2g = 13.4g
        Quantity of diluent needed = 13.4g - 0.201g = 13.2g
        Stock powder is 0.201g of ivermectin + 13.2g of microcrystalline cellulose
        Aliquot for 10 capsules = 10 x MWQ = 10 x 0.2g = 2g
        Additional diluent needed to bulk fill capsule shells:
        #1 - 0.262g - 0.2g = 0.062g x10 capsules = 0.62g
        #0 - 0.36g - 0.2g = 0.16g x10 capsules = 1.6g
        Final mixture 2g of stock powder (aliquot) + 0.62g diluent #1    Total diluent used 13.2 +0.62 = 13.82g
        Final mixture 2g of stock powder (aliquot) + 1.6g diluent #0     Total diluent used 13.2 +1.6 = 14.8g
        `,
		questions: `1. Soft gel capsules contain a plasticizer, hard-shell capsules do not contain a plasticizer
		2. Soft gel capsules are 1 piece, hard-shell capsules are comprised of 2 pieces
        3. Fill components can be liquid for soft gel capsule, but not hard-shell capsules
        `,
	});

	// State to store the grading result from the API
	const [gradingResult, setGradingResult] = useState<any>(null);

	// State to indicate loading status
	const [isLoading, setIsLoading] = useState(false);

	// State to handle any errors from the API
	const [apiError, setApiError] = useState<string | null>(null);

	// Utility function to check if a field is filled
	const isFieldFilled = (value: string) => value.trim().length > 0;

	// Check if all fields in a section are filled
	const isSectionComplete = useCallback(
		(sectionId: string) => {
			if (sectionId === "student_info") {
				return (
					isFieldFilled(studentInfo.studentName) &&
					isFieldFilled(studentInfo.labStation) &&
					isFieldFilled(studentInfo.scenarioId)
				);
			} else if (sectionId === "formulation") {
				return (
					isFieldFilled(formulation.name) &&
					isFieldFilled(formulation.strength) &&
					isFieldFilled(formulation.quantityPrepared) &&
					isFieldFilled(formulation.quantityDispensed) &&
					isFieldFilled(formulation.therapeuticCategory) &&
					isFieldFilled(formulation.routeOfAdministration) &&
					isFieldFilled(formulation.dosageForm)
				);
			} else if (sectionId === "ingredients") {
				return ingredientRows.every(
					(row) =>
						isFieldFilled(row.ingredient) &&
						isFieldFilled(row.quantity) &&
						isFieldFilled(row.description) &&
						isFieldFilled(row.use)
				);
			} else if (sectionId === "manufacturer_info") {
				return manufacturerRows.every(
					(row) =>
						isFieldFilled(row.ingredient) &&
						isFieldFilled(row.manufacturer) &&
						isFieldFilled(row.lot) &&
						isFieldFilled(row.expirationDate)
				);
			} else if (sectionId === "equipment") {
				return equipmentList.every((item) => isFieldFilled(item.equipment));
			} else if (sectionId === "preparation") {
				return isFieldFilled(otherSections.preparation);
			} else if (sectionId === "calculations") {
				return isFieldFilled(otherSections.calculations);
			} else if (sectionId === "label") {
				return (
					isFieldFilled(labelInfo.pharmacyName) &&
					isFieldFilled(labelInfo.dateDispensed) &&
					isFieldFilled(labelInfo.serialNumber) &&
					isFieldFilled(labelInfo.patientName) &&
					isFieldFilled(labelInfo.prescriberName) &&
					isFieldFilled(labelInfo.drugName) &&
					isFieldFilled(labelInfo.directions) &&
					isFieldFilled(labelInfo.expirationDate) &&
					isFieldFilled(labelInfo.quantity) &&
					isFieldFilled(labelInfo.refills) &&
					isFieldFilled(labelInfo.dispenser)
				);
			} else if (sectionId === "description") {
				return (
					isFieldFilled(descriptionFields.station) &&
					isFieldFilled(descriptionFields.elegantCapsules) &&
					isFieldFilled(descriptionFields.colorUniformity) &&
					isFieldFilled(descriptionFields.averageWeight)
				);
			} else if (sectionId === "questions") {
				return isFieldFilled(otherSections.questions);
			}
			return false;
		},
		[
			studentInfo,
			formulation,
			ingredientRows,
			manufacturerRows,
			equipmentList,
			labelInfo,
			descriptionFields,
			otherSections,
		]
	);

	// Check if all sections are complete
	const areAllSectionsComplete = useCallback(() => {
		return sections.every((section) => isSectionComplete(section.id));
	}, [sections, isSectionComplete]);

	// Enable or disable the submit button based on section completion
	useEffect(() => {
		setIsSubmitEnabled(areAllSectionsComplete());
	}, [areAllSectionsComplete]);

	// Render the checkmark if the section is complete
	const renderCheckmark = (sectionId: string) => {
		if (isSectionComplete(sectionId)) {
			return <CheckCircleOutline sx={{ color: "green", marginLeft: 1 }} />;
		}
		return null;
	};

	// Handle form submission for grading
	const handleSubmitForGrading = async () => {
		// Create a studentSubmission object with all the form data
		const studentSubmission = {
			formulation,
			ingredients: ingredientRows,
			manufacturerInfo: manufacturerRows,
			equipment: equipmentList.map((item) => item.equipment),
			preparation: otherSections.preparation,
			calculations: otherSections.calculations,
			label: labelInfo,
			description: descriptionFields,
			questions: otherSections.questions,
		};

		setGradingResult(null);
		setApiError(null);
		setIsLoading(true);

		// Send the studentSubmission object to the API along with scenarioId and studentId
		try {
			const response = await axios.post(
				"https://lsi3qi0psi.execute-api.us-east-1.amazonaws.com/prod/grade_submission",
				{
					studentSubmission,
					scenarioId: studentInfo.scenarioId,
					scenarioName: studentInfo.scenarioName,
					studentId: studentInfo.studentName,
					labStation: studentInfo.labStation,
				},
				{
					headers: {
						"Content-Type": "application/json",
					},
				}
			);
			console.log("Response:", response.data);
			// Parse and set the grading result
			const gradingResultData = response.data.gradingResult;

			// If the grading result is a JSON string, parse it
			let parsedResult;
			try {
				parsedResult = JSON.parse(gradingResultData);
			} catch (parseError) {
				// If parsing fails, use the raw data
				parsedResult = gradingResultData;
			}
			// Handle success: e.g., show success message, reset form, etc.
			setGradingResult(parsedResult);
		} catch (error: any) {
			console.error("Error submitting for grading:", error);
			setApiError(error.message || "An error occurred while submitting.");
		} finally {
			setIsLoading(false);
		}
	};

	// Handle input changes for Student Information
	const handleStudentInfoChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setStudentInfo({ ...studentInfo, [name]: value });
	};

	// Handle Formulation section input changes
	const handleFormulationChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setFormulation({ ...formulation, [name]: value });
	};

	// Handle dynamic ingredient rows
	const handleIngredientChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		const newRows = [...ingredientRows];
		newRows[index][name as keyof (typeof newRows)[0]] = value;
		setIngredientRows(newRows);
	};

	// Handle dynamic manufacturer rows
	const handleManufacturerChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		const newRows = [...manufacturerRows];
		newRows[index][name as keyof (typeof newRows)[0]] = value;
		setManufacturerRows(newRows);
	};

	// Handle dynamic equipment input
	const handleEquipmentChange = (
		index: number,
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { value } = e.target;
		const newList = [...equipmentList];
		newList[index].equipment = value;
		setEquipmentList(newList);
	};

	// Handle checkbox changes in Description fields
	const handleCheckboxChange = (name: string, value: string) => {
		setDescriptionFields({ ...descriptionFields, [name]: value });
	};

	// Handle other sections input changes
	const handleOtherSectionsChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setOtherSections({ ...otherSections, [name]: value });
	};

	// Add and remove rows for ingredients, manufacturer, and equipment
	const addRow = (type: string) => {
		if (type === "ingredients" && ingredientRows.length < 20) {
			setIngredientRows([
				...ingredientRows,
				{ ingredient: "", quantity: "", description: "", use: "" },
			]);
		} else if (type === "manufacturer" && manufacturerRows.length < 20) {
			setManufacturerRows([
				...manufacturerRows,
				{ ingredient: "", manufacturer: "", lot: "", expirationDate: "" },
			]);
		} else if (type === "equipment" && equipmentList.length < 20) {
			setEquipmentList([...equipmentList, { equipment: "" }]);
		}
	};

	const removeRow = (type: string, index: number) => {
		if (type === "ingredients") {
			const newRows = [...ingredientRows];
			newRows.splice(index, 1);
			setIngredientRows(newRows);
		} else if (type === "manufacturer") {
			const newRows = [...manufacturerRows];
			newRows.splice(index, 1);
			setManufacturerRows(newRows);
		} else if (type === "equipment") {
			const newList = [...equipmentList];
			newList.splice(index, 1);
			setEquipmentList(newList);
		}
	};

	// Handle section dragging
	const handleDragEnd = (result: any) => {
		if (!result.destination) return;
		const updatedSections = Array.from(sections);
		const [removed] = updatedSections.splice(result.source.index, 1);
		updatedSections.splice(result.destination.index, 0, removed);
		setSections(updatedSections);
	};

	// Handle input changes for Label section
	const handleLabelChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setLabelInfo({ ...labelInfo, [name]: value });
	};

	return (
		<Box sx={{ padding: "2rem", display: "flex", justifyContent: "center" }}>
			<Card
				sx={{
					width: "80%",
					padding: "2rem",
					borderRadius: "15px",
					boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
				}}
			>
				<Typography variant="h4" align="center" sx={{ marginBottom: "2rem" }}>
					Dr. Nornoo's Compounding Lab
				</Typography>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="sections">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef}>
								{sections.map((section, index) => (
									<Draggable
										key={section.id}
										draggableId={section.id}
										index={index}
										isDragDisabled={section.id === "student_info"}
									>
										{(provided) => (
											<Box
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												sx={{ marginBottom: "1rem" }}
											>
												<Card
													sx={{
														borderRadius: "12px",
														boxShadow: "0px 3px 12px rgba(0, 0, 0, 0.1)",
														padding: "1rem",
													}}
												>
													<CardContent>
														<Typography
															variant="h6"
															sx={{ marginBottom: "1rem", fontWeight: "bold" }}
														>
															{section.label} {renderCheckmark(section.id)}
														</Typography>

														{/* Student Information Section */}
														{section.id === "student_info" ? (
															<Grid container spacing={2}>
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Student Name"
																		name="studentName"
																		value={studentInfo.studentName}
																		onChange={handleStudentInfoChange}
																		placeholder="Enter student's name..."
																	/>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Laboratory Station"
																		name="labStation"
																		value={studentInfo.labStation}
																		onChange={handleStudentInfoChange}
																		placeholder="Enter laboratory station..."
																	/>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Scenario Id"
																		name="scenarioId"
																		value={studentInfo.scenarioId}
																		onChange={handleStudentInfoChange}
																		placeholder="Enter scenario number..."
																	/>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Scenario Name"
																		name="scenarioName"
																		value={studentInfo.scenarioName}
																		onChange={handleStudentInfoChange}
																		placeholder="Enter scenario name..."
																	/>
																</Grid>
															</Grid>
														) : section.id === "formulation" ? (
															<Grid container spacing={2}>
																{/* Left side of Formulation section */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		multiline
																		rows={4}
																		variant="outlined"
																		label="Name of Formulation"
																		name="name"
																		value={formulation.name}
																		onChange={handleFormulationChange}
																		placeholder="Enter name of formulation..."
																	/>
																</Grid>

																{/* Right side fields for Quantity */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Quantity Prepared"
																		name="quantityPrepared"
																		value={formulation.quantityPrepared}
																		onChange={handleFormulationChange}
																		placeholder="Enter quantity prepared..."
																		sx={{ marginBottom: 2 }}
																	/>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Quantity Dispensed"
																		name="quantityDispensed"
																		value={formulation.quantityDispensed}
																		onChange={handleFormulationChange}
																		placeholder="Enter quantity dispensed..."
																	/>
																</Grid>

																{/* Left side for Strength */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Strength of Active Ingredient(s)"
																		name="strength"
																		value={formulation.strength}
																		onChange={handleFormulationChange}
																		placeholder="Enter strength..."
																	/>
																</Grid>

																{/* Right side for Therapeutic Category */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Therapeutic Category"
																		name="therapeuticCategory"
																		value={formulation.therapeuticCategory}
																		onChange={handleFormulationChange}
																		placeholder="Enter therapeutic category..."
																	/>
																</Grid>

																{/* Left side for Dosage Form */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Dosage Form"
																		name="dosageForm"
																		value={formulation.dosageForm}
																		onChange={handleFormulationChange}
																		placeholder="Enter dosage form..."
																	/>
																</Grid>

																{/* Right side for Route of Administration */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Route of Administration"
																		name="routeOfAdministration"
																		value={formulation.routeOfAdministration}
																		onChange={handleFormulationChange}
																		placeholder="Enter route of administration..."
																	/>
																</Grid>
															</Grid>
														) : section.id === "label" ? (
															// Label Section with all 11 fields and an image
															<Grid container spacing={2}>
																<Grid item xs={12}>
																	<Typography variant="h6">
																		Prescription Label Information
																	</Typography>
																</Grid>
																{/* Image of the label */}
																<Grid item xs={12} sm={3}>
																	<img
																		src="/apple-touch-icon.png"
																		alt="Label Image"
																		style={{
																			width: "50%",
																			maxWidth: "100%",
																			borderRadius: "8px",
																		}}
																	/>
																</Grid>

																{/* Pharmacy Name and Address */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		multiline
																		rows={2}
																		variant="outlined"
																		label="Pharmacy Name and Address"
																		name="pharmacyName"
																		value={`${labelInfo.pharmacyName}\n${labelInfo.pharmacyAddress}`}
																		onChange={handleLabelChange}
																		placeholder="Enter pharmacy name and address"
																	/>
																</Grid>
																<Grid item xs={12} sm={3}></Grid>
																{/* Prescriber Name */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Prescriber Name"
																		name="prescriberName"
																		value={labelInfo.prescriberName}
																		onChange={handleLabelChange}
																		placeholder="Enter prescriber's name"
																	/>
																</Grid>
																<Grid item xs={12}>
																	<Divider />
																</Grid>
																{/* Rx Number */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Rx Number"
																		name="serialNumber"
																		value={labelInfo.serialNumber}
																		onChange={handleLabelChange}
																		placeholder="Enter the Rx or serial number"
																	/>
																</Grid>
																<Grid item xs={12} sm={6}></Grid>
																{/* Date Dispensed */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Date Dispensed"
																		name="dateDispensed"
																		value={labelInfo.dateDispensed}
																		onChange={handleLabelChange}
																		placeholder="Enter the dispensing date"
																	/>
																</Grid>
																{/* Patient Name */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Patient Name"
																		name="patientName"
																		value={labelInfo.patientName}
																		onChange={handleLabelChange}
																		placeholder="Enter patient's name"
																	/>
																</Grid>
																<Grid item xs={12} sm={9}></Grid>
																<Grid item xs={12}>
																	<Divider />
																</Grid>
																{/* Drug Name */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Drug Name"
																		name="drugName"
																		value={labelInfo.drugName}
																		onChange={handleLabelChange}
																		placeholder="Enter the drug name"
																	/>
																</Grid>
																<Grid item xs={12} sm={6}></Grid>
																{/* Directions for Use */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Directions for Use"
																		name="directions"
																		value={labelInfo.directions}
																		onChange={handleLabelChange}
																		placeholder="Enter directions for use"
																	/>
																</Grid>
																<Grid item xs={12} sm={6}></Grid>
																<Grid item xs={12}>
																	<Divider />
																</Grid>
																{/* Quantity */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Quantity"
																		name="quantity"
																		value={labelInfo.quantity}
																		onChange={handleLabelChange}
																		placeholder="Enter quantity"
																	/>
																</Grid>
																<Grid item xs={12} sm={3}></Grid>
																{/* Dispenser */}
																<Grid item xs={6} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Dispenser"
																		name="dispenser"
																		value={labelInfo.dispenser}
																		onChange={handleLabelChange}
																		placeholder="Enter the name of the dispenser"
																	/>
																</Grid>
																{/* Refills */}
																<Grid item xs={12} sm={3}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Refills"
																		name="refills"
																		value={labelInfo.refills}
																		onChange={handleLabelChange}
																		placeholder="Enter refills"
																	/>
																</Grid>
																<Grid item xs={12} sm={3}></Grid>
																{/* Expiration Date */}
																<Grid item xs={12} sm={6}>
																	<TextField
																		fullWidth
																		variant="outlined"
																		label="Expiration Date"
																		name="expirationDate"
																		value={labelInfo.expirationDate}
																		onChange={handleLabelChange}
																		placeholder="Enter expiration date"
																	/>
																</Grid>
															</Grid>
														) : section.id === "manufacturer_info" ? (
															<>
																<Table>
																	<TableHead>
																		<TableRow>
																			<TableCell>Ingredient</TableCell>
																			<TableCell>Manufacturer</TableCell>
																			<TableCell>Lot #</TableCell>
																			<TableCell>Expiration Date</TableCell>
																			<TableCell>Actions</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{manufacturerRows.map((row, idx) => (
																			<TableRow key={idx}>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Ingredient"
																						name="ingredient"
																						value={row.ingredient}
																						onChange={(e) =>
																							handleManufacturerChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Manufacturer"
																						name="manufacturer"
																						value={row.manufacturer}
																						onChange={(e) =>
																							handleManufacturerChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Lot #"
																						name="lot"
																						value={row.lot}
																						onChange={(e) =>
																							handleManufacturerChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Expiration Date"
																						name="expirationDate"
																						value={row.expirationDate}
																						onChange={(e) =>
																							handleManufacturerChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<IconButton
																						onClick={() =>
																							removeRow("manufacturer", idx)
																						}
																						color="secondary"
																						disabled={
																							manufacturerRows.length === 1
																						}
																					>
																						<Remove />
																					</IconButton>
																				</TableCell>
																			</TableRow>
																		))}
																	</TableBody>
																</Table>

																<Button
																	variant="outlined"
																	startIcon={<Add />}
																	onClick={() => addRow("manufacturer")}
																	sx={{ marginTop: 2 }}
																	disabled={manufacturerRows.length >= 20}
																>
																	Add Manufacturer Row
																</Button>
															</>
														) : section.id === "equipment" ? (
															<>
																{equipmentList.map((item, idx) => (
																	<Grid
																		container
																		spacing={2}
																		key={idx}
																		alignItems="center"
																		sx={{ marginBottom: 1 }}
																	>
																		<Grid item xs={10}>
																			<TextField
																				fullWidth
																				variant="outlined"
																				label="Equipment or Instrument"
																				value={item.equipment}
																				onChange={(e) =>
																					handleEquipmentChange(idx, e)
																				}
																			/>
																		</Grid>
																		<Grid item xs={2}>
																			<IconButton
																				onClick={() =>
																					removeRow("equipment", idx)
																				}
																				color="secondary"
																				disabled={equipmentList.length === 1}
																			>
																				<Remove />
																			</IconButton>
																		</Grid>
																	</Grid>
																))}

																<Button
																	variant="outlined"
																	startIcon={<Add />}
																	onClick={() => addRow("equipment")}
																	sx={{ marginTop: 2 }}
																	disabled={equipmentList.length >= 20}
																>
																	Add Equipment/Instrument
																</Button>
															</>
														) : section.id === "ingredients" ? (
															// Ingredients Table
															<>
																<Table>
																	<TableHead>
																		<TableRow>
																			<TableCell>Ingredient</TableCell>
																			<TableCell>Quantity Used</TableCell>
																			<TableCell>
																				Physical Description
																			</TableCell>
																			<TableCell>
																				Use in the Prescription
																			</TableCell>
																			<TableCell>Actions</TableCell>
																		</TableRow>
																	</TableHead>
																	<TableBody>
																		{ingredientRows.map((row, idx) => (
																			<TableRow key={idx}>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Ingredient"
																						name="ingredient"
																						value={row.ingredient}
																						onChange={(e) =>
																							handleIngredientChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Quantity Used"
																						name="quantity"
																						value={row.quantity}
																						onChange={(e) =>
																							handleIngredientChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Physical Description"
																						name="description"
																						value={row.description}
																						onChange={(e) =>
																							handleIngredientChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<TextField
																						fullWidth
																						variant="outlined"
																						label="Use in the Prescription"
																						name="use"
																						value={row.use}
																						onChange={(e) =>
																							handleIngredientChange(idx, e)
																						}
																					/>
																				</TableCell>
																				<TableCell>
																					<IconButton
																						onClick={() =>
																							removeRow("ingredients", idx)
																						}
																						color="secondary"
																						disabled={
																							ingredientRows.length === 1
																						}
																					>
																						<Remove />
																					</IconButton>
																				</TableCell>
																			</TableRow>
																		))}
																	</TableBody>
																</Table>

																<Button
																	variant="outlined"
																	startIcon={<Add />}
																	onClick={() => addRow("ingredients")}
																	sx={{ marginTop: 2 }}
																	disabled={ingredientRows.length >= 20}
																>
																	Add Ingredient Row
																</Button>
															</>
														) : section.id === "description" ? (
															// Description of Finished Product and Station Cleanliness
															<Grid container spacing={2}>
																{/* Station: Clean or Dirty */}
																<Grid item xs={12} sm={6}>
																	<Typography>Station</Typography>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<FormGroup row>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.station ===
																						"clean"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"station",
																							"clean"
																						)
																					}
																				/>
																			}
																			label="Clean"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.station ===
																						"dirty"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"station",
																							"dirty"
																						)
																					}
																				/>
																			}
																			label="Dirty"
																		/>
																	</FormGroup>
																</Grid>

																{/* Elegant Capsules: Yes or No */}
																<Grid item xs={12} sm={6}>
																	<Typography>Elegant capsules</Typography>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<FormGroup row>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.elegantCapsules ===
																						"yes"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"elegantCapsules",
																							"yes"
																						)
																					}
																				/>
																			}
																			label="Yes"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.elegantCapsules ===
																						"no"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"elegantCapsules",
																							"no"
																						)
																					}
																				/>
																			}
																			label="No"
																		/>
																	</FormGroup>
																</Grid>

																{/* Color Uniformity: Yes or No */}
																<Grid item xs={12} sm={6}>
																	<Typography>
																		Color (capsule dye) uniformity
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<FormGroup row>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.colorUniformity ===
																						"yes"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"colorUniformity",
																							"yes"
																						)
																					}
																				/>
																			}
																			label="Yes"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.colorUniformity ===
																						"no"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"colorUniformity",
																							"no"
																						)
																					}
																				/>
																			}
																			label="No"
																		/>
																	</FormGroup>
																</Grid>

																{/* Average Weight: Correct or Incorrect */}
																<Grid item xs={12} sm={6}>
																	<Typography>
																		Average weight of 6 capsules
																	</Typography>
																</Grid>
																<Grid item xs={12} sm={6}>
																	<FormGroup row>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.averageWeight ===
																						"correct"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"averageWeight",
																							"correct"
																						)
																					}
																				/>
																			}
																			label="Correct"
																		/>
																		<FormControlLabel
																			control={
																				<Checkbox
																					checked={
																						descriptionFields.averageWeight ===
																						"incorrect"
																					}
																					onChange={() =>
																						handleCheckboxChange(
																							"averageWeight",
																							"incorrect"
																						)
																					}
																				/>
																			}
																			label="Incorrect"
																		/>
																	</FormGroup>
																</Grid>
															</Grid>
														) : (
															// Default content for other sections
															<TextField
																fullWidth
																multiline
																rows={4}
																variant="outlined"
																label={section.label}
																name={section.id}
																placeholder={`Enter ${section.label}...`}
																value={
																	otherSections[
																		section.id as keyof typeof otherSections
																	]
																}
																onChange={handleOtherSectionsChange}
															/>
														)}
													</CardContent>
												</Card>
											</Box>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{/* Submit button */}
				{/* Submit button */}
				<Box
					sx={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}
				>
					<Button
						variant="contained"
						color="primary"
						sx={{
							borderRadius: "25px",
							padding: "0.75rem 2rem",
							fontWeight: "bold",
							fontSize: "16px",
							backgroundColor: isSubmitEnabled ? "#3f51b5" : "#ccc",
							"&:hover": {
								backgroundColor: isSubmitEnabled ? "#2c387e" : "#ccc",
							},
						}}
						disabled={!isSubmitEnabled}
						onClick={handleSubmitForGrading} // Connect the submit button to the submission handler
					>
						{isLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Submit For Grading"
						)}
					</Button>
				</Box>
				{/* Grading Result or Error Display */}
				{isLoading && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "1rem",
						}}
					>
						<Typography variant="body1">Grading in progress...</Typography>
					</Box>
				)}
				{apiError && (
					<Box sx={{ marginTop: "1rem" }}>
						<Card sx={{ backgroundColor: "#ffe5e5", padding: "1rem" }}>
							<Typography variant="h6" color="error">
								Error:
							</Typography>
							<Typography variant="body1">{apiError}</Typography>
						</Card>
					</Box>
				)}
				{gradingResult && !apiError && (
					<Box sx={{ marginTop: "2rem" }}>
						<Card sx={{ padding: "1rem" }}>
							<Typography variant="h6">Grading Result:</Typography>
							<pre style={{ whiteSpace: "pre-wrap" }}>
								{typeof gradingResult === "object"
									? JSON.stringify(gradingResult, null, 2)
									: gradingResult}
							</pre>
						</Card>
					</Box>
				)}
			</Card>
		</Box>
	);
};

export default NornooPage;
