import React from "react";
import { CircularProgress } from "@mui/material";

interface RightColumnProps {
	loadingAnthropic: boolean;
	responseAnthropic: React.ReactNode;
	loadingOpenAI: boolean;
	responseOpenAI: React.ReactNode;
	loadingGoogle: boolean;
	responseGoogle: React.ReactNode;
}

const RightColumn: React.FC<RightColumnProps> = ({
	loadingAnthropic,
	responseAnthropic,
	loadingOpenAI,
	responseOpenAI,
	loadingGoogle,
	responseGoogle,
}) => {
	// Explicitly type the style object
	const responseContainerStyle: React.CSSProperties = {
		border: "1px solid #ddd",
		padding: "10px",
		borderRadius: "8px",
		margin: "10px",
		width: "30%", // Fixed width for each response box
		height: "400px", // Fixed height for each response box
		overflowY: "auto", // Enable scrolling for each response
		backgroundColor: "#f9f9f9",
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "space-between",
				width: "100%",
			}}
		>
			{/* Anthropic Response */}
			<div style={responseContainerStyle}>
				<h3>Anthropic</h3>
				{loadingAnthropic ? (
					<CircularProgress sx={{ color: "blue" }} />
				) : (
					responseAnthropic
				)}
			</div>

			{/* OpenAI Response */}
			<div style={responseContainerStyle}>
				<h3>OpenAI</h3>
				{loadingOpenAI ? (
					<CircularProgress sx={{ color: "blue" }} />
				) : (
					responseOpenAI
				)}
			</div>

			{/* Google Response */}
			<div style={responseContainerStyle}>
				<h3>Google</h3>
				{loadingGoogle ? (
					<CircularProgress sx={{ color: "blue" }} />
				) : (
					responseGoogle
				)}
			</div>
		</div>
	);
};

export default RightColumn;
