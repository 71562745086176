import React from "react";
import { TextField, Box } from "@mui/material";

const SystemUserMessages: React.FC<{
	systemMessage: string;
	setSystemMessage: (value: string) => void;
	userMessage: string;
	setUserMessage: (value: string) => void;
	handleSubmit: (e: React.FormEvent) => void;
	disabled: boolean; // Add the disabled prop to the type definition
}> = ({
	systemMessage,
	setSystemMessage,
	userMessage,
	setUserMessage,
	handleSubmit,
	disabled, // Include disabled in the component props
}) => {
	return (
		<Box>
			<TextField
				fullWidth
				label="System Message"
				variant="outlined"
				value={systemMessage}
				onChange={(e) => setSystemMessage(e.target.value)}
				sx={{ marginBottom: 2 }}
				disabled={disabled} // Disable when responses are loading
				required
			/>

			<TextField
				fullWidth
				label="User Message"
				variant="outlined"
				value={userMessage}
				onChange={(e) => setUserMessage(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === "Enter" && !disabled) {
						e.preventDefault(); // Prevent form submission on Enter
						handleSubmit(e as any); // Call submit on Enter
					}
				}}
				disabled={disabled} // Disable when responses are loading
				sx={{ marginBottom: 2 }}
				required
			/>
		</Box>
	);
};

export default SystemUserMessages;
